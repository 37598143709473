<template>
  <v-hover>
    <v-card
        slot-scope="{ hover }"
        :elevation="hover ? 12 : 2"
        class="mb-5"
        max-width="340"
        min-width="340"
        rounded="lg"
    >
      <v-card-text>
        <v-chip
            v-for="c in dados.calendarios"
            :key="c.situacaoProcesso.codigo"
            :color="defineSituacaoProcessoCor(c.situacaoProcesso.codigo)"
            class="mx-2 my-1"
            small
            dark
        >{{ c.situacaoProcesso.nome.substring(0, 21) }}
        </v-chip>
      </v-card-text>
      <v-divider/>
      <v-card-title class="justify-center text-break titulo">
        {{ dados.nome }}
      </v-card-title>
      <v-fade-transition>
        <v-overlay v-if="hover" absolute color="secondary">
          <v-btn
              color="accent"
              @click.prevent="acessarProcessoDivulgacao(dados.oid)"
          >Acessar
          </v-btn>
        </v-overlay>
      </v-fade-transition>
    </v-card>
  </v-hover>
</template>

<script>
import utils from "@/commons/utils";

export default {
  name: "ProcessoCard",

  props: {
    dados: {type: Object, required: true}
  },

  methods: {
    ...utils,

    acessarProcessoDivulgacao(processoOid) {
      this.$router
          .push({
            name: "ProcessoDivulgacao",
            params: {oid: processoOid}
          })
          .catch(() => {
          });
    }
  }
};
</script>
