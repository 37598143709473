<template>
  <v-container v-if="!loading" fluid>
    <transition
        appear
        enter-active-class="animate__animated animate__fadeIn"
        mode="out-in"
    >
      <div>
        <div v-if="processosPublicados.length">
          <h2 class="text-center mb-3 titulo">
            PROCESSOS COM EDITAIS PUBLICADOS
          </h2>
          <v-divider class="mb-5"/>
        </div>
        <v-row
            v-if="processosPublicados.length"
            key="processos"
            justify="center"
        >
          <ProcessoCard
              v-for="p in processosPublicados"
              :key="p.nome"
              :dados="p"
              class="mx-2"
          />
        </v-row>
        <v-row
            v-else-if="!loading"
            key="semProcessos"
            align="center"
            class="mt-10"
            justify="center"
        >
          <v-card elevation="5" rounded>
            <v-card-title class="titulo">
              Não existem processos em andamento. Aguarde!
            </v-card-title>
            <v-divider/>
            <v-card-text class="text-center">
              <desk-animation/>
            </v-card-text>
            <v-divider/>
            <v-card-subtitle class="text-center">
              Para mais informações acesse <a href="https://www.acessograduacao.ufrj.br" target="_blank">acessograduacao.ufrj.br</a>
            </v-card-subtitle>
          </v-card>
        </v-row>
      </div>
    </transition>
  </v-container>
</template>

<script>
import ProcessoCard from "@/components/pages/PaginaInicial/ProcessoCard";
import processoService from "@/services/processo.service";
import DeskAnimation from "@/components/pages/PaginaInicial/DeskAnimation";

export default {
  name: "PaginaInicial",

  components: {
    DeskAnimation,
    ProcessoCard
  },

  data() {
    return {
      processosPublicados: [],
      loading: true
    };
  },
  async created() {
    let loader = this.$loading.show();

    await processoService
        .recuperarProcessosPublicados()
        .then(response => {
          this.processosPublicados = response.data;
        })
        .catch(() => {
        });

    loader.hide();
    this.loading = false;
  }
};
</script>
